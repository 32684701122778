import { Loader2, Search as SearchIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useDebounceFetcher } from 'remix-utils/use-debounce-fetcher'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '~/components/ui/command'

import { Form, Link, useNavigate } from 'react-router'
import { shouldHideFeature } from '~/config/features'
import type {
  AirportSearchResult,
  LoungeSearchResult,
} from '~/routes/api+/_app/v1+/search/_search'
import AirportIcon from './icons/AirportIcon'
import LoungeIcon from './icons/LoungeIcon'
import { Button } from './ui/scn-button'

export function SearchAutocomplete() {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const commandRef = useRef<HTMLDivElement>(null)

  const fetcher = useDebounceFetcher<{
    hasResults: boolean
    results: {
      Airports: AirportSearchResult[]
      Lounges: LoungeSearchResult[]
    }
  }>()

  // Determine if we're currently loading results
  const isLoading =
    fetcher.state === 'submitting' || fetcher.state === 'loading'

  const showResults = !isLoading && fetcher.data?.hasResults && isOpen

  // Handle search input changes
  useEffect(() => {
    if (searchQuery.length >= 3) {
      // Using fetcher to load search results
      fetcher.submit(
        {
          search: searchQuery,
        },
        {
          method: 'GET',
          navigate: false,
          action: '/api/v1/search',
          debounceTimeout: 300,
        },
      )

      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [searchQuery])

  function handleEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()
      navigate({
        pathname: '/search',
        search: `q=${encodeURIComponent(searchQuery)}`,
      })
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        commandRef.current &&
        !commandRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Add escape key handler
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false)
    }
  }

  const renderResults = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center py-6">
          <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
        </div>
      )
    }

    if (!fetcher.data?.hasResults && isOpen) {
      return (
        <CommandEmpty className="py-6 text-center text-sm text-muted-foreground">
          No results found
        </CommandEmpty>
      )
    }

    if (showResults) {
      return (
        <>
          {fetcher.data?.results.Airports && (
            <CommandGroup heading="Airports">
              {fetcher.data?.results.Airports.map((airport) => (
                <CommandItem
                  key={airport.id}
                  value={airport.id}
                  onSelect={() => {
                    setIsOpen(false)
                  }}
                >
                  <Link
                    to={`/at/${airport.iata}/`}
                    className="px-6 py-2 cursor-pointer hover:bg-blue-100"
                  >
                    <div className="flex items-start gap-x-2">
                      <AirportIcon className="mt-0.5" />
                      <span className="font-semibold">
                        {airport.name} ({airport.iata})
                      </span>
                    </div>

                    {airport.location && (
                      <span className="block w-full ml-6 text-sm text-midnight-600">
                        {airport.location}
                      </span>
                    )}
                  </Link>
                </CommandItem>
              ))}
            </CommandGroup>
          )}
          {fetcher.data?.results.Lounges && (
            <>
              <CommandSeparator />
              <CommandGroup heading="Lounges">
                {fetcher.data?.results.Lounges.map((lounge) => (
                  <CommandItem
                    key={lounge.id}
                    value={lounge.id}
                    onSelect={() => {
                      setIsOpen(false)
                    }}
                  >
                    <Link
                      to={`/at/${lounge.iata}/${lounge.slug}/`}
                      className="px-6 py-2 cursor-pointer hover:bg-blue-100"
                    >
                      <div className="flex items-start gap-x-2">
                        <LoungeIcon className="mt-0.5" />
                        <span className="font-semibold">
                          {lounge.name} ({lounge.iata})
                        </span>
                      </div>

                      {lounge.location && (
                        <span className="block w-full ml-6 text-sm text-midnight-600">
                          {lounge.location}
                        </span>
                      )}
                    </Link>
                  </CommandItem>
                ))}
              </CommandGroup>
            </>
          )}
        </>
      )
    }

    return null
  }

  // Hide on voucher create page
  if (shouldHideFeature('nav.search')) return null

  return (
    <div className="relative w-full max-w-lg">
      <Command
        // Causes issues with async results
        shouldFilter={false}
        ref={commandRef}
        onKeyDown={handleKeyDown}
        className="group border-0 !rounded-[100px] !bg-midnight-100 has-[:focus]:ring-2 has-[:focus]:ring-blue-400 has-[:focus]:ring-offset-2"
      >
        <Form action="/search">
          <CommandInput
            placeholder="City, airport, or lounge"
            name="q"
            value={searchQuery}
            onValueChange={setSearchQuery}
            onKeyDown={handleEnter}
            className="w-full border-0 focus:ring-0 pl-0 sm:ml-3"
          >
            <Button size="sm" rounded="full" className="-mr-1.5" type="submit">
              <SearchIcon />
              <span className="hidden sm:flex sm:justify-center sm:items-center sm:gap-2">
                Search
              </span>
            </Button>
          </CommandInput>
        </Form>

        <CommandList className="absolute z-20 bg-white max-sm:rounded-t-none rounded-2xl mt-14 shadow-xl w-screen lg:w-full -ml-[65px] sm:-ml-[196px] lg:ml-0">
          {renderResults()}
        </CommandList>
      </Command>
    </div>
  )
}
