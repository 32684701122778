import { type UIMatch, useMatches } from 'react-router'

interface Features extends Record<string, unknown> {
  nav: {
    search: boolean
    accounts: boolean
  }
  footer: boolean
}

/**
 * Utility to walk through an object by a dot-delimited key path.
 * Returns the nested boolean if it is defined, otherwise undefined.
 */
function getNestedFeature(
  features: Record<string, unknown>,
  featurePath: string,
): boolean | undefined {
  const keys = featurePath.split('.')
  let current: unknown = features
  for (const key of keys) {
    if (current && typeof current === 'object' && key in current) {
      current = (current as Record<string, unknown>)[key]
    } else {
      return undefined
    }
  }
  return typeof current === 'boolean' ? current : undefined
}

/**
 * Return true if all matching route handles either don’t provide the nested feature
 * (assumed true) or explicitly provide a true for the nested feature.
 */
export function shouldShowFeature(featurePath: string) {
  const matches = useMatches() as UIMatch<unknown, { features: Features }>[]
  return matches.every((m) => {
    const features = m.handle?.features
    if (!features) return true

    const result = getNestedFeature(features, featurePath)
    return typeof result === 'boolean' ? result : true
  })
}

/**
 * Return true if any matching route handle explicitly sets the nested feature to false.
 */
export function shouldHideFeature(featurePath: string) {
  const matches = useMatches() as UIMatch<unknown, { features: Features }>[]
  return matches.some((m) => {
    const features = m.handle?.features
    if (!features) return false

    const result = getNestedFeature(features, featurePath)
    return result === false
  })
}
